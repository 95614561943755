
<template>
<div class="content-wrap">
  <Nav></Nav>
  <HeaderStyle :title="title" :describe="describe" :backgroundUrl="backgroundUrl"></HeaderStyle>
  <div class="new-box">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/ComDynamics' }">公司动态</el-breadcrumb-item>
        <el-breadcrumb-item>动态详情</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <!-- 标题 -->

    <div class="flex-wa">
        <div class="left-con">
            <div class="img-text">
                <p class="new-title">{{cmsTitle}}</p>
                <div class="sub-desc">
                    <span classs="resource">来源：{{extra1}} </span>
                    <span class="time">{{ $moment(publishTime).format("YYYY-MM-DD")}} </span>
                </div>
            </div>
            <div class="new-content">
                <div v-html="cmsContent"></div>
            </div>
        </div>

        <div class="right-con">
            <div class="title">相关新闻</div>
             <div class="new-info">
                <div class="info-item" v-for="(item,index) in newList" :key="index">
                    <div @click="getInfo(item.id)">
                        <div class="info" :title='item.title'>{{item.title}}</div>
                        <div class="time">{{$moment(item.time).format("YYYY-MM-DD")}}</div>
                    </div>
                    
                </div>
                </div>
        </div>
    </div>

  
   
    <!-- <div class="new-content" v-for="(item,index) in contentInfo" :key="index">
        <p>{{item.content}}</p>
        <img :src="item.imgurl" alt="">
    </div> -->
  </div>
</div>
 
</template>

<script>
import api from "@/api/index";
import AppFooter from '@/components/layer/AppFooter'
import SerAsk from '@/components/layer/SerAsk'
import HeaderStyle from '@/components/commom/HeaderStyle'
import Nav from "@/components/layer/HeaderNav";
export default {
  name: 'new',
   components: {
    AppFooter,
    SerAsk,
    HeaderStyle,
    Nav
  },
  data() {
    return {
        extra1:'',
        publishTime:'',
         newList:[],
        title:"公司动态",
        describe:"聚合公司新闻、媒体报道，社交媒体活动及更多信息",
        backgroundUrl:"backgroundUrl",
        contentInfo:[{
            content:"4月7日下午，北京市人大常委会副主任李颖津来我司调研预付式消费立法和资金监管工作。北京市委副秘书长张铁军在石景山区做预付式消费信用监管和服务工作的专题调研工作。在国家会议中心参加全国政法智能化建设技术装备及成果展。",
            imgurl:require("@/assets/comDynamics/new1.png"),
        },
        {
            content:"4月7日下午，北京市人大常委会副主任李颖津来我司调研预付式消费立法和资金监管工作。北京市委副秘书长张铁军在石景山区做预付式消费信用监管和服务工作的专题调研工作。在国家会议中心参加全国政法智能化建设技术装备及成果展。",
            imgurl:require("@/assets/comDynamics/new1.png"),
        }],

        cmsTitle:'',
        cmsContent:''
 }
 },
 	mounted() {
		this.getInfo();
        this.getList();


	},
    methods:{
      getList() {
           let categoryId = 1 //  首页 1
			api.news.getList({
				articleId: null,
				categoryId: categoryId,
				pageNum: 1,
				pageSize: 3, // 1 5 
			}).then((res) => {
            if(res.code == 0){
            let pageInfo = res.data.pageInfo
            this.total = pageInfo.total          
            if(pageInfo && pageInfo.list && pageInfo.list.length){
                pageInfo.list.forEach(item => {
                    item.title = item.title
                    item.time = item.publishTime
                    item.content = item.description
                });
                this.newList = pageInfo.list
            }
            
            }
        });
    	},
         // 新闻详情
		getInfo(id) {
            let articleId = this.$route.query.id
            if(id){
                 articleId = id
            }
			api.news.getInfo({ articleId: articleId }).then((res) => {
                console.log("res",res)
                if(res.code == 0){
                     let Data = res.data
                     this.cmsTitle = Data.title
                     this.cmsContent = Data.content
                     this.extra1 = Data.extra1
                     this.publishTime = Data.publishTime
                }
            });
		},
    }
}
</script>
<style lang="scss" scoped>
.new-box{
    width: 14.32rem;
    margin:0 auto;
    padding-top:0.40rem
}
.breadcrumb{
     border-bottom: 1px solid #ddd;
     padding-bottom: 0.4rem;

}
.flex-wa{
    display: flex;
    margin-top:0.4rem;
    width: 14.32rem;

}
.left-con{
    width: 9.5rem;
    margin-right: 0.5rem;;
}
.right-con{
     padding-left: 0.3rem;
     border-left: 1px solid #ddd;
     width: 4.02rem;
     .title{
         font-size: 0.22rem;
        //  margin-top: 0.63rem;
         margin-bottom:0.24rem ;
     }
     .info-item{
       margin-bottom:0.17rem;
       border-bottom: 1px solid #ddd;
        padding-bottom: 0.1rem;

        .info{
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;
            cursor: pointer;
        }
     }
    .info-item:last-child{
      margin-bottom:0;
    }
    .info{
      font-weight: 600;
      font-size: 0.18rem;
      margin-bottom:0.15rem;
    }
    .time{
      font-size: 0.16rem;
    }
}
.new-content{
    width:9.5rem;
    padding-bottom:0.40rem;
    // margin-left:1.22rem;
    p{
        font-family: PingFangSC-Light;
        font-size: 0.16rem;
        color: #1C1C1C;
        line-height: 0.36rem;
    }
    /deep/ img{
        max-width:9.5rem;
        margin-top:16px;
    }
}
.img-text{
    // margin-left:1.22rem;
    width: 9.5rem;;
    margin-top:0.13rem;
}
.new-title{
    font-family: PingFangSC-Semibold;
    font-size: 0.28rem;
color: #000000;
line-height: 0.42rem;
}
.sub-desc{
    display: flex;
    font-family: PingFangSC-Regular;
// font-size: 0.14rem;
font-size: 14px;
color: #1C1C1C;
line-height: 0.18rem;
padding:0.24rem 0 0.40rem 0;
.time{
    margin-left: 20px;
}
}
.backgroundUrl{
    background-image:url("../../assets/comDynamics/dongtai.png");
    background-size:100% 100% no-repeat;
}
</style>